<template>
  <div
    class="overflow-y-auto overflow-x-hidden flex flex-column"
  >
    <mobile-group-header
      label="임대 계약서"
      description="빨간 별(*)은 필수 입력 사항입니다. 파란 별(*)모양은 전자계약서 필요 항목입니다."
    />
    <template v-if="!!internalValue">
      <mobile-input
        v-model="internalValue.contractNo"
        required
        label="계약서 번호"
      />
      <mobile-select
        v-model="internalValue.payType"
        label="결제 방법"
        :items="selectItems.payType"
        required-blue
      />
      <mobile-datepicker
        v-model="internalValue.contractDate"
        label="계약일"
        required
      />
      <mobile-datepicker
        v-model="internalValue.contractStartdate"
        label="계약시작일"
        required
      />
      <mobile-datepicker
        v-model="internalValue.contractEnddate"
        label="계약종료일"
        required
      />
      <mobile-select
        v-model="internalValue.billDay"
        label="결제일"
        required
        :items="itemsOfDay"
      />
      <mobile-select
        v-model="internalValue.cmsMemberId"
        label="CMS"
        :items="itemOfCmsMember"
      />
      <mobile-select
        v-model="internalValue.taxSchDay"
        label="세금계산서 발행일"
        :items="selectItems.addedTaxItems.concat(itemsOfDay)"
      />
      <mobile-select
        v-if="!!internalValue.taxSchDay && internalValue.taxSchDay > 0 && internalValue.taxSchDay <= 31"
        v-model="internalValue.taxOption"
        label="입금확인 후 발행"
        :items="selectItems.taxOptionsItems"
      />
      <mobile-select
        v-model="internalValue.tmId"
        label="세금계산서"
        :items="itemOfTaxMember"
      />
      <mobile-select
        v-model="internalValue.statementIssueDay"
        label="거래명세서 발행일"
        :items="selectItems.addedStatementItems.concat(itemsOfDay)"
      />
      <mobile-select
        v-model="internalValue.tsmId"
        label="거래명세서"
        :items="itemOfTransactionMember"
      />
      <mobile-select
        v-model="internalValue.calculationStartdate"
        label="계산시작일"
        :items="itemsOfDay"
      />
      <mobile-input
        v-model="internalValue.memo"
        label="비고"
      />
      <mobile-button
        v-if="!!contractId && contractId !== 0"
        v-model="internalValue.attachments"
        button-type="file"
        label="첨부파일"
        @onClick="fileDownload"
      />
      <mobile-slide-button
        v-if="!!contractId && contractId !== 0"
        v-model="electricalContract"
        label="전자계약서"
        button-type="link"
        @onClick="onClickElectrical"
      />
    </template>
  </div>
</template>

<script>
import {DateTime} from "luxon";
import {PATH} from "@/constants";
import {cloneDeep} from "lodash";

export default {
  name: "ContractBase",
  components: {},
  props: {
    accountId: undefined,
    value: {
      type: Object,
      default: undefined,
    },
    contractId: {
      type: [Number, String],
      default: undefined,
    },
    electricalContract: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      internalValue: undefined,
      selectItems: {
        payType: [
          {label: '선택안함', value: null, key: 'pay-type-0'},
          {label: '계좌이체', value: 'B', key: 'pay-type-1'},
          {label: '카드', value: 'C', key: 'pay-type-2'},
        ],
        addedTaxItems: [
          {label: '발행안함', value: '', key: 'day-item-null'},
          {label: '입금확인 후 익월발행', value: '-1', key: 'day-item--1'},
        ],
        addedStatementItems: [
          {label: '발행안함', value: 0, key: 'day-item-null'},
        ],
        emptyItems: [
          {label: '선택안함', value: null, key: 'empty-item'},
        ],
        taxOptionsItems: [
          {label: '사용', value: 'Y', key: 'tex-option-item-Y'},
          {label: '미사용', value: 'N', key: 'tex-option-item-N'},
        ]
      },
      requestData: {
        cmsMember: [],
        taxMember: [],
        transactionMember: [],
      },
    }
  },
  computed: {
    itemsOfDay() {
      const result = []
      for (let i = 1; i < 32; i++) {
        result.push({label: `${i}일`, value: i, key: `day-item-${i}`})
      }
      return result
    },
    itemOfCmsMember() {
      return this.selectItems.emptyItems.concat(this.requestData.cmsMember.map((e, idx) => {
        return {label: e.nickname, value: e.cmsMemberId, key: `cms-item-${idx}`}
      }))
    },
    itemOfTaxMember() {
      return this.selectItems.emptyItems.concat(this.requestData.taxMember.map((e, idx) => {
        return {label: e.nickname, value: e.tmId, key: `tax-item-${idx}`}
      }))
    },
    itemOfTransactionMember() {
      return this.selectItems.emptyItems.concat(this.requestData.transactionMember.map((e, idx) => {
        return {label: e.nickname, value: e.tsmId, key: `transaction-item-${idx}`}
      }))
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.internalValue = newValue
      }
    },
    internalValue: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.$emit('input', newValue)
      }
    }
  },
  mounted() {
    this.internalValue = this.value
    this.loadMembers()
  },
  methods: {
    /**
     * CMS, 세금계산서, 거래명세서 Member 리스트 로드
     * @returns {Promise<void>}
     */
    async loadMembers() {
      try {
        const requestList = []
        requestList.push(this.loadCmsMember())
        requestList.push(this.loadTaxMember())
        requestList.push(this.loadTransactionMember())
        const result = await Promise.all(requestList)
        this.requestData.cmsMember = result[0].data
        this.requestData.taxMember = result[1].data
        this.requestData.transactionMember = result[2].data
      } catch (e) {
        console.error(e)
      }
    },

    /**
     * CMS 리스트 로드
     * @returns {Promise<void>}
     */
    async loadCmsMember() {
      const result = await this.$request(PATH.CMS.GET_CMS_MEMBERS).setObject({accountId: this.accountId}).enqueue()
      return result
    },
    /**
     * 세금계산서 Member 리스트 로드
     * @returns {Promise<void>}
     */
    async loadTaxMember() {
      const result = await this.$request(PATH.TAX.GET_TAX_MEMBERS).setObject({accountId: this.accountId}).enqueue()
      return result
    },
    /**
     * CMS, 세금계산서, 거래명세서 Member 리스트 로드
     * @returns {Promise<void>}
     */
    async loadTransactionMember() {
      const result = await this.$request(PATH.TRANSACTION.GET_TRANSACTION_MEMBERS).setObject({accountId: this.accountId}).enqueue()
      return result
    },

    async fileDownload(attach) {
      try {
        const url = `${PATH.BASE_URL}${PATH.RESOURCE.FILE}?path=${encodeURI(attach.path)}&filename=${encodeURI(attach.originalFilename)}`;
        location.href = url;
      } catch (e) {
        console.error(e);
        this.$alert("파일 경로가 올바르지 않습니다.");
      }
    },

    onClickElectrical(item){
      this.$emit('go-electrical', item)
    }
  },
}
</script>

<style scoped>
</style>
