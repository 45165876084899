<template>
  <div class="sub_item_wrap">
    <div
      class="flex justify-space-between align-center"
      @click="isShow = !isShow"
    >
      <div class="sub_item_title">
        {{ detailShowTitle }}
      </div>
      <div
        class="button-group"
      >
        <div
          class="del-button"
        >
          <v-icon v-if="isShow">
            mdi-chevron-up
          </v-icon>
          <v-icon v-else>
            mdi-chevron-down
          </v-icon>
        </div>
      </div>
    </div>
    <div
      v-if="isShow"
      style="
      margin-bottom: 0.5rem;
      margin-top: 0.5rem;
      border-bottom: 2px solid #aaaaaa;
    "
    />
    <div
      class="overflow-hidden flex flex-column"
    >
      <template v-if="isShow">
        <mobile-select
          v-model="internalValue.itemGroupCode"
          label="대분류"
          required
          :items="itemGroupCodeList"
        />
        <mobile-printer-auto-complete
          v-if="internalValue.itemGroupCode === '01'"
          label="품목"
          required
          :contract-context="internalValue"
          @selected="selectedItem"
        />
        <mobile-select
          v-else
          v-model="internalValue.companyModelId"
          :items="filteredItemList"
          label="품목"
          required
        />
        <mobile-input
          v-model="computedBaseFee"
          label="기본요금"
          type="number"
          required
          unit="원"
          :hidden="!salesConfigPermission"
        />
        <mobile-input
          v-model="internalValue.itemQty"
          label="품목수량"
          type="number"
          :min="1"
          required
          unit="ea"
        />
        <mobile-input
          v-model="computedItemPrice"
          label="월 단가"
          sub-label="1ea"
          type="number"
          required
          unit="원"
          :hidden="!salesConfigPermission"
        />
        <mobile-input
          v-model="internalValue.deposit"
          label="보증금"
          type="number"
          unit="원"
        />
        <mobile-input
          v-model="internalValue.installLocation"
          label="설치장소"
        />
        <!-- 자동화 설정 -->
        <template>
          <mobile-select
            v-model="computedAutomationConfig"
            :items="selectItems.automationItems"
            label="자동매출대상"
            description="자동 매출 생성 시 설정한 대상만 생성합니다."
          />
          <mobile-datepicker
            v-model="internalValue.startSalesMonth"
            :disabled="computedAutomationConfig === '1'"
            label="자동매출 시작월"
            picker-type="month"
            date-format="yyyy-MM"
            description="설정한 월 부터 자동 매출이 생성 됩니다."
          />
        </template>
        <!-- 프린터 상세 내용 -->
        <template v-if="internalValue.itemGroupCode === '01'">
          <mobile-select
            v-model="internalValue.engineCountYn"
            :items="selectItems.engineItems"
            label="엔진카운트"
            description="HP 잉크젯 프린터만 해당합니다. 그외 프린터는 '미사용'으로 설정해 주세요."
          />
          <mobile-select
            v-model="internalValue.a3DoubleCountYn"
            :items="selectItems.a3doubleItems"
            label="A3용지"
            description="A3 사용량이 수집될 경우 설정한 '배수'만큼 A4 사용량으로 계산합니다."
          />
          <mobile-select
            v-model="internalValue.colorYn"
            :disabled="internalValue.engineCountYn === 'Y'"
            :items="selectItems.colorItems"
            label="컬러유무"
            description="'흑백'으로 설정할 경우 컬러 수집량이 흑백으로 취급됩니다."
          />
          <mobile-input
            v-model="internalValue.basePagecount"
            label="기본매수"
            sub-label="흑백"
            unit="매"
            type="number"
          />
          <mobile-input
            v-model="internalValue.basePagecountColor"
            :disabled="internalValue.colorYn !== 'Y' || internalValue.engineCountYn === 'Y'"
            label=""
            sub-label="컬러"
            unit="매"
            type="number"
          />
          <mobile-input
            v-model="internalValue.additionalFee"
            label="추가요금 (장당)"
            sub-label="흑백"
            unit="원"
            type="number"
            :hidden="!salesConfigPermission"
          />
          <mobile-input
            v-model="internalValue.additionalFeeColor"
            :disabled="internalValue.colorYn !== 'Y' || internalValue.engineCountYn === 'Y'"
            label=""
            sub-label="컬러"
            unit="원"
            type="number"
            class="border-none"
            :hidden="!salesConfigPermission"
          />
        </template>
        <mobile-input
          v-model="internalValue.comment"
          label="비고"
        />
        <template v-if="!!internalValue.printerContractId && internalValue.itemGroupCode === '01'">
          <printer-contract-install-log-component
            v-if="installLogShow"
            :contract-context="internalValue"
            :account-id="accountId"
          />
          <div
            v-if="installLogShow"
            class="install_log_btn"
            @click.stop="installLogShow = !installLogShow"
          >
            {{ '설치내역 닫기'| translate }}
            <img
              src="@/assets/images/main/svg/acceptAarrow.svg"
              class="ml-2"
              style="transform: rotate(-90deg);"
            >
          </div>
          <div
            v-else
            class="install_log_btn"
            @click.stop="installLogShow = !installLogShow"
          >
            {{ '설치내역 보기' | translate }}
            <img
              src="@/assets/images/main/svg/acceptAarrow.svg"
              class="ml-2"
              style="transform: rotate(90deg);"
            >
          </div>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import PrinterContractInstallLogComponent
  from "@/components/items/printer-contract/PrinterContractInstallLogComponent";
import userManager from "@/assets/plugins/mps-common/user/user-manager";
import Permissions from "@/constants/permissions";

export default {
  name: "ContractSubItem",
  components: {PrinterContractInstallLogComponent},
  props: {
    value: {
      type: Object,
      default: undefined
    },
    accountId: {
      type: Number,
      default: undefined
    },
    itemGroupCodeList: {
      type: Array,
      default: () => []
    },
    itemCodeList: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      internalValue: undefined,
      installLogShow: false,
      isShow: true,
      selectItems: {
        automationItems: [
          {label: '발생 안함', value: '1', key: 'auto-target-0'},
          {label: '기본료', value: '2', key: 'auto-target-1'},
          {label: '기본+추가료', value: '3', key: 'auto-target-2'},
        ],
        engineItems: [
          {label: '미사용', value: 'N', key: 'engine-item-0'},
          {label: '사용', value: 'Y', key: 'engine-item-1'},
        ],
        a3doubleItems: [
          {label: '1배', value: 'N', key: 'a3double-item-0'},
          {label: '2배', value: 'Y', key: 'a3double-item-1'},
        ],
        colorItems: [
          {label: '흑백', value: 'N', key: 'color-item-0'},
          {label: '컬러', value: 'Y', key: 'color-item-1'},
        ]
      },
    }
  },
  computed: {
    salesConfigPermission() {
      return userManager.hasPermission(Permissions.EXPOSE_ACCOUNT_INFO_SALES_CONFIG)
    },
    /**
     * 하위계약 타이틀
     * @returns {string}
     */
    detailShowTitle() {
      try {
        const itemGroup = this.itemGroupCodeList.find(e => e.value === this.internalValue.itemGroupCode).label
        const item = this.internalValue.itemGroupCode === '01' && !!this.internalValue.printerModelName ? this.internalValue.printerModelName :
          !!this.internalValue.companyModelId ? this.filteredItemList.find(e => e.value === this.internalValue.companyModelId).label : ''
        const result = `${itemGroup}: ${item}`

        return `${result.length > 30 ? result.substring(0, 28) + '...' : result}`
      } catch (e) {
        return ''
      }
    },

    /**
     * 품목 리스트
     * @returns {*[]}
     */
    filteredItemList() {
      return this.itemCodeList.filter(e => e.groupValue === this.internalValue.itemGroupCode)
    },

    /**
     * 기본금액
     */
    computedBaseFee: {
      get() {
        if (this.internalValue.contractFeeYn === 'Y') return this.internalValue.contractFee
        else return this.internalValue.baseFee
      },

      set(newValue) {
        this.internalValue.contractFee = Number(newValue)
        this.internalValue.baseFee = Number(newValue)
      },
    },

    /**
     * 품목별 금액
     */
    computedItemPrice: {
      get() {
        try {
          return this.computedBaseFee / (this.internalValue.itemQty === 0 ? 1 : this.internalValue.itemQty)
        } catch (e) {
          return 0
        }
      },

      set(newValue) {
        this.internalValue.itemPrice = Number(newValue.toFixed(2))
        this.computedBaseFee = newValue * (this.internalValue.itemQty === 0 ? 1 : this.internalValue.itemQty)
      }
    },

    /**
     * 자동화 설정
     */
    computedAutomationConfig: {
      get() {
        if (this.internalValue.automaticallyConfirmSales === 'N' &&
          this.internalValue.automaticallyAdditionalSalesYn === 'N') {
          return '1'
        } else if (this.internalValue.automaticallyConfirmSales === 'Y' &&
          this.internalValue.automaticallyAdditionalSalesYn === 'N') {
          return '2'
        } else if (this.internalValue.automaticallyConfirmSales === 'Y' &&
          this.internalValue.automaticallyAdditionalSalesYn === 'Y') {
          return '3'
        }
        return '0'
      },

      set(newValue) {
        switch (newValue) {
          case '1':
            this.internalValue.contractFeeYn = 'Y'
            this.internalValue.rentalType = 'S'
            this.internalValue.automaticallyConfirmSales = 'N'
            this.internalValue.automaticallyAdditionalSalesYn = 'N'
            break
          case '2':
            this.internalValue.contractFeeYn = 'Y'
            this.internalValue.rentalType = 'S'
            this.internalValue.automaticallyConfirmSales = 'Y'
            this.internalValue.automaticallyAdditionalSalesYn = 'N'
            break
          case '3':
            this.internalValue.contractFeeYn = 'N'
            this.internalValue.rentalType = 'P'
            this.internalValue.automaticallyConfirmSales = 'Y'
            this.internalValue.automaticallyAdditionalSalesYn = 'Y'
            break
        }
      },
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.internalValue = newValue
      }
    },
    internalValue: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.$emit('input', newValue)
      }
    },

    /**
     * 아이템 대분류 변경 시
     * @param newValue
     */
    'internalValue.itemGroupCode'(newValue) {
      if (newValue !== '01') {
        this.resetItemInfo()
        this.internalValue.modelType = 'ITEM'
      } else {
        this.internalValue.modelType = null
      }
    },

    'internalValue.contractFee'(newValue) {
      this.internalValue.baseFee = newValue
    },
  },

  mounted() {
    this.internalValue = this.value
  },

  methods: {
    /**
     * 기본정보 리셋
     */
    resetItemInfo() {
      this.internalValue.printerModelName = null;
      this.internalValue.printerModelId = 0;
      this.internalValue.prtSerial = null;
      this.internalValue.monitoringPrinterId = null;
      this.internalValue.installLocation = null;
      this.internalValue.monitoringPrinterContractId = null;
      this.internalValue.companyModelId = null;
      this.internalValue.modelType = null
      this.internalValue.comment = null
    },

    selectedItem(item) {
      if (!item || item.isPrinter || item.isModel || item.isCodeModel) {
        this.resetItemInfo()
      }

      if (!!item && item.isPrinter) {
        this.internalValue.printerModelName = item.raw.printerModelName;
        this.internalValue.printerModelId = item.raw.printerModelId;
        this.internalValue.monitoringPrinterId = item.raw.monitoringPrinterId;
        this.internalValue.installLocation = item.raw.installLocation;
        this.internalValue.monitoringPrinterContractId = item.raw.monitoringPrinterContractId;
        this.internalValue.prtSerial = item.raw.printerSerialNo;
      } else if (!!item && item.isModel) {
        this.internalValue.printerModelName = item.raw.printerModelName;
        this.internalValue.printerModelId = item.raw.printerModelId;
      } else if (!!item && item.isCodeModel) {
        this.internalValue.printerModelName = item.raw.codeValue;
        this.internalValue.printerModelId = 0;
        this.internalValue.modelType = "CPMDL";
        this.internalValue.companyModelId = item.raw.codeKey;
      }
    }
  },
}
</script>

<style scoped>
.sub_item_wrap {
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
}

.sub_item_title {
  font-weight: bold;
  color: #333;
}

.button-group {
  display: flex;
  width: fit-content;
}

.show-button {
  background-image: url('/img/ic_list_arrow_down.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 0.9rem;
  margin: 0 0.25rem;
  cursor: pointer;
  width: 1.8rem;
  height: 1.8rem;
}

.is-show {
  transform: rotate(180deg);
}

.del-button {
  margin: 0 0.25rem;
  cursor: pointer;
  height: 1.8rem;
  line-height: 1.8rem;
}

.install_log_btn {
  padding: 9px;
  background: #ebebeb;
  font-weight: bold;
  margin: 5px;
  font-size: 0.9375rem;
}
</style>
