<template>
  <div class="overflow-y-auto overflow-x-hidden">
    <my-tool-bar :title="'계약서'">
      <template #buttons>
        <toolbar-save-action
          @save="onSave"
          @refresh="onRefresh"
        />
      </template>
    </my-tool-bar>
    <div style="position: relative; top: 56px; height: calc(100% - 56px);">
      <template v-if="!ui.show.loading && !ui.show.subLoading">
        <contract-base
          v-model="editContext"
          :electrical-contract="electricalContract"
          :contract-id="contractId"
          :account-id="accountId"
          @go-electrical="goElectrical"
        />
        <contract-sub-list
          v-model="editSubContext"
          :rental-contract-info="editContext"
          :account-id="accountId"
          :contract-id="contractId"
        />
      </template>
      <template v-else>
        <progress-loading />
      </template>
    </div>
  </div>
</template>

<script>
/**
 * 임대계약 및 프린터 계약 화면입니다.
 */

import {PATH} from "@/constants";
import {cloneDeep} from "lodash";
import WindowComponent from "@/assets/plugins/mps-window-mobile/WindowComponent";
import MyToolBar from "../../app/toolbar/MyToolBar";
import ToolbarSaveAction from "../../app/toolbar/ToolbarSaveAction";
import ProgressLoading from "../../part/ProgressLoading";
import ContractBase from "./ContractBase";
import ContractSubList from "./ContractSubList";
import VuexProps from "@/framework/vuex/VuexProps";
import {DateTime} from "luxon";
import {ETC} from "@/constants/etc-code";
import userManager from "@/assets/plugins/mps-common/user/user-manager";
import Permissions from "@/constants/permissions";
export default {
  name: "ContractRegister",
  components: {ContractSubList, ContractBase, ProgressLoading, ToolbarSaveAction, MyToolBar},
  extends: WindowComponent,
  mixins: [VuexProps],
  props: {
    accountId: undefined,
    contractId: undefined,
  },
  data() {
    return {
      accountInfo: undefined,
      context: {
        contractId: undefined,
        contractNo: undefined,
        contractDate: undefined,
        contractType: undefined,
        contractStartdate: undefined,
        contractEnddate: undefined,
        payType: null,
        realYn: undefined,
        contractAmount: undefined,
        memo: undefined,
        contractFee: undefined,
        filename: undefined,
        orgFilename: undefined,
        contractCancelDate: undefined,
        contractCancel: undefined,
        contractState: undefined,
        serialVersionUID: undefined,
        billDay: undefined,
        calculationStartdate: undefined,
        taxSchDay: undefined,
        statementIssueDay: undefined,
        cmsMemberId: undefined,
        tmId: undefined,
        tsmId: undefined,
      },
      editContext: undefined,
      subContext: undefined,
      editSubContext: undefined,
      electricalContract: undefined,
      ui: {
        show: {
          loading: false,
          subLoading: false
        }
      },
      dummy: {
        contractId: undefined,
        contractNo: undefined,
        contractType: 1,
        contractDate: undefined,
        contractStartdate: undefined,
        contractEnddate: undefined,
        payType: null,
        realYn: 'Y',
        contractAmount: undefined,
        memo: undefined,
        contractFee: undefined,
        contractCancelDate: undefined,
        contractCancel: undefined,
        contractState: undefined,
        billDay: undefined,
        calculationStartdate: undefined,
        taxSchDay: undefined,
        taxOption: 'N',
        statementIssueDay: undefined,
        cmsMemberId: undefined,
        tmId: undefined,
        tsmId: undefined,
      }
    }
  },
  computed: {
    editPermission() {
      return userManager.hasPermission(Permissions.EXPOSE_ACCOUNT_INFO_REGIST_EDIT)
    }
  },
  watch: {
    context: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.editContext = cloneDeep(newValue)
      }
    },
    subContext: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.editSubContext = cloneDeep(newValue)
      }
    }
  },
  mounted() {
    this.loadAccountInfo()
    if (!!this.contractId && this.contractId != null && this.contractId !== 0 & this.contractId !== '0') {
      this.loadData()
    }
    localStorage.setItem('ACCOUNT_DETAIL_TAB', 0)
  },
  unmounted() {
    this.context = undefined
    this.editContext = undefined
    this.subContext = undefined
    this.editSubContext = undefined
  },
  methods: {
    /**
     * 기초 데이터 로드
     */
    loadData() {
      this.loadDetail()
      this.loadElectrical()
      this.loadSubItems()
    },

    /**
     * 거래처 정보 로드
     * @returns {Promise<void>}
     */
    async loadAccountInfo() {
      const response = await this.$request(PATH.ACCOUNT.GET)
        .setObject({
          accountId: this.accountId,
        })
        .enqueue();
      this.accountInfo = response
      const response2 = await this.$request(PATH.ACCOUNT.AUTOMATION)
        .setObject({
          accountId: this.accountId,
        })
        .enqueue();
      this.accountInfo.automation = response2
      if (!this.contractId || this.contractId === 0 || this.contractId === '0') {
        const newItem = cloneDeep(this.dummy)

        if (!newItem.contractNo) {
          newItem.contractNo = this.accountId + "_" + DateTime.local().toFormat('yyMMddHHmmss')
        }
        if (!newItem.accountId) {
          newItem.accountId = this.accountId
        }
        newItem.contractStartdate = DateTime.local().toSQLDate()
        newItem.contractEnddate = DateTime.local().plus({year: 1}).toSQLDate()
        newItem.contractDate = newItem.contractStartdate
        newItem.billDay = this.accountInfo.automation.billDay | 1
        newItem.calculationStartdate = this.accountInfo.automation.calculationStartdate | 1
        newItem.taxSchDay = this.accountInfo.automation.taxSchDay
        newItem.statementIssueDay = this.accountInfo.automation.statementIssueDay
        newItem.cmsMemberId = this.accountInfo.automation.cmsMemberId
        newItem.tmId = this.accountInfo.automation.tmId
        newItem.tsmId = this.accountInfo.automation.tsmId

        this.context = newItem
      }
    },

    /**
     * 계약서 상세 정보 로드
     * @returns {Promise<void>}
     */
    async loadDetail() {
      if (!this.contractId || this.contractId === 0 || this.contractId === '0') return
      this.ui.show.loading = true;
      try {
        const response = await this.$request(PATH.CONTRACT.DETAIL)
          .setObject({contractId: this.contractId})
          .enqueue();
        this.context = response;
      } catch (e) {
        console.error(e)
        this.$snackbarError(this.$translate("데이터를 가져오지 못했습니다."));
      }
      this.ui.show.loading = false;
    },

    /**
     * 전자계약서 리스트 로드
     * @returns {Promise<void>}
     */
    async loadElectrical() { // CONTRACT_SELECT_BY_CONTRACT
      if (!this.contractId || this.contractId === 0 || this.contractId === '0') return
      try {
        const response = await this.$request(PATH.ELECTRICAL.CONTRACT_SELECT_BY_CONTRACT + `/${this.contractId}`)
          .enqueue();
        this.electricalContract = response.data
      } catch (e) {
        console.error(e)
        this.$snackbarError(this.$translate("데이터를 가져오지 못했습니다."));
      }
    },

    /**
     * 하위 계약서 로드
     * @returns {Promise<void>}
     */
    async loadSubItems() {
      if (!this.contractId || this.contractId === 0 || this.contractId === '0') return
      this.ui.show.subLoading = true;
      try {
        const response = await this.$request(PATH.PRINTER_CONTRACT.LIST)
          .setObject({contractId: this.contractId})
          .enqueue();
        this.subContext = response.data
      } catch (error) {
        console.error(e)
        this.$snackbarError(this.$translate("데이터를 가져오지 못했습니다."));
      }
      this.ui.show.subLoading = false;
    },

    /**
     * 리셋 버튼 클릭
     * @returns {Promise<void>}
     */
    async onRefresh() {
      this.$dialog()
        .message(this.$translate('편집중인 정보를 초기화합니다.'))
        .buttonNegative(this.$translate('취소'))
        .buttonPositive(this.$translate('초기화'), () => {
          this.editContext = cloneDeep(this.context)
          this.editSubContext = cloneDeep(this.subContext)
        })
        .build().show();
    },

    /**
     * 저장 버튼 클릭
     * @returns {Promise<*>}
     */
    async onSave() {
      if(!this.editPermission) {
        return this.$snackbarError(ETC.PERMISSION.ACCOUNT_REGISTER_EDIT);
      }
      if (this.checkValidate()) {
        return this.$snackbarError(this.$translate("필수 항목을 작성하여 주세요"));
      }

      this.$dialog()
        .message(this.$translate('저장하시겠습니까?'))
        .buttonNegative(this.$translate('취소'))
        .buttonPositive(this.$translate('저장'), () => {
          this.contractSave()
        })
        .build().show();
    },

    /**
     * 벨리데이션 체크
     * @returns {*}
     */
    checkValidate() {
      const checked = this.editSubContext.some(e => {
        return (e.modelType === 'ITEM' && (!e.companyModelId || e.companyModelId == null || e.companyModelId === 0 || e.companyModelId === '0'))
          || (e.modelType !== 'ITEM' && e.printerModelName == null)
      })

      return checked
    },

    /**
     * 계약서 정보 저장
     * @returns {Promise<*>}
     */
    async contractSave() {
      if (this.ui.show.loading) return
      this.ui.show.loading = true;

      let contractRes
      if (!this.contractId || this.contractId === 0 || this.contractId === '0') {
        try {
          contractRes = await this.$request(PATH.ADD_CONTRACT)
            .multipart()
            .appendObject('context', this.editContext)
            .enqueue();
        } catch (e) {
          console.error(e)
          return this.$snackbarError(this.$translate("데이터를 저장하지 못했습니다."));
        } finally {
          this.ui.show.loading = false;
        }
      } else if (this.$isDirty(this.context, this.editContext)) {
        try {
          contractRes = await this.$request(PATH.CONTRACT_UPDATE)
            .multipart()
            .appendObject('context', this.editContext)
            .enqueue();
        } catch (e) {
          console.error(e)
          return this.$snackbarError(this.$translate("데이터를 저장하지 못했습니다."));
        } finally {
          this.ui.show.loading = false;
        }
      }

      this.subContractSave(contractRes)
    },

    /**
     * 하위 계약서 저장
     * @param contractRes
     * @returns {Promise<*>}
     */
    async subContractSave(contractRes) {
      this.ui.show.loading = true;

      const subContractList = this.editSubContext.map(e => {
        e.accountId = Number(this.accountId)
        e.contractId = (!this.contractId || this.contractId === 0 || this.contractId === '0') ? Number(contractRes.contractId) : Number(this.contractId)
        if (!e.contractStartdate) {
          e.contractDate = this.editContext.contractDate
          e.contractStartdate = this.editContext.contractStartdate
          e.contractEnddate = this.editContext.contractEnddate
        }
        return e
      }).filter(e => {
        const originItem = !this.subContext ? {} : this.subContext.find(ee => ee.printerContractId === e.printerContractId)
        return !e.printerContractId || this.$isDirty(originItem, e)
      })

      try {
        if (subContractList.length > 0) {
          await this.$request(PATH.PRINTER_CONTRACT.INSERT_UPDATE)
            .setObject(subContractList)
            .enqueue();
        }
      } catch (e) {
        console.error(e)
        return this.$snackbarError(this.$translate("데이터를 저장하지 못했습니다."));
      } finally {
        this.ui.show.loading = false;
      }

      this.$snackbarSuccess(this.$translate("계약서 저장이 완료되었습니다."));

      const params = {
        contractId: contractRes.contractId,
        contractNo: contractRes.contractNo,
        accountId: contractRes.accountId,
      }
      this.routerManager.replace({name: "ContractRegister", params});
    },

    /**
     * 전자계약서 화면으로 이동
     */
    goElectrical(item) {
      if(!this.editPermission) {
        return this.$snackbarError(ETC.PERMISSION.ACCOUNT_REGISTER_EDIT);
      }
      const params = {
        contractId: !!item ? 0 : this.contractId,
        accountId: !!item ? 0 : this.accountId,
        electricalContractId: !!item ? item.electricalContractId : 0,
        item: item
      }
      this.$nextTick(() => {
        this.$dialog()
          .message(this.$translate('전자계약서 화면으로 이동할까요?'))
          .buttonNegative(this.$translate('취소'))
          .buttonPositive(this.$translate('이동'), () => {
            this.routerManager.push({
              name: "ElectricalContract", params
            });
          })
          .build()
          .show();
      })
    }
  },
}
</script>

<style scoped>
</style>
