<template>
  <div
    class="overflow-y-auto overflow-x-hidden flex flex-column"
  >
    <mobile-group-header label="품목 계약서 목록" />

    <div class="add-button-wrap">
      <button
        class="add-button"
        @click.stop="addSubContract"
      >
        품목 추가
      </button>
    </div>

    <div class="contract-sub-list-wrap">
      <div
        v-for="(item, index) in internalValue"
        :key="'contract-sub-item-' + ( item.printerContractId | index)"
        class="contract-sub-wrap"
      >
        <div class="contract_wrap">
          <contract-sub-item
            :key="'contract-sub-item-' + ( item.printerContractId | index)"
            v-model="internalValue[index]"
            :item-group-code-list="itemGroupCodeList"
            :item-code-list="itemCodeList"
            class="contract-sub-item"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {PATH} from "@/constants";
import ContractSubItem from "./ContractSubItem";
import {cloneDeep} from "lodash";
import userManager from "@/assets/plugins/mps-common/user/user-manager";
import {ETC} from "@/constants/etc-code";
import Permissions from "@/constants/permissions";

export default {
  name: "ContractSubList",
  components: {ContractSubItem},
  props: {
    rentalContractInfo: {
      type: Object,
      default: undefined,
    },
    value: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      internalValue: undefined,
      itemGroupCodeList: undefined,
      itemCodeList: undefined,
      dummy: {
        printerContractId: undefined,
        accountId: undefined,
        contractId: undefined,
        contractEnddate: undefined,
        contractStartdate: undefined,
        contractState: 1,
        //대분류
        itemGroupCode: '01',
        modelType: undefined,
        //품목
        companyModelId: undefined,
        printerModelId: 0,
        itemQty: 1,
        itemPrice: 0,
        //기본요금
        contractFee: 0,
        baseFee: 0,
        deposit: 0,
        //매출 설정
        contractFeeYn: 'Y',
        rentalType: 'S',
        automaticallyConfirmSales: 'N',
        automaticallyAdditionalSalesYn: 'N',
        startSalesMonth: undefined,
        //용지
        engineCountYn: 'N',
        a3DoubleCountYn: 'N',
        colorYn: 'N',
        basePagecount: undefined,
        basePagecountColor: undefined,
        additionalFee: undefined,
        additionalFeeColor: undefined,

        // etc
        monitoringPrinterContractId: undefined,
        monitoringPrinterId: undefined,
        oldMonitoringPrinterContractId: undefined,
        replaceYn: undefined,
        installLocation: undefined,
        comment: undefined,
        usageManagementYn: 'N',

        printerModelName: null,
      },
    }
  },
  computed: {
    accountEditPermission() {
      return userManager.hasPermission(Permissions.EXPOSE_ACCOUNT_INFO_REGIST_EDIT)
    }
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.internalValue = newValue
      }
    },
    internalValue: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.$emit('input', newValue)
      }
    },
  },

  mounted() {
    this.internalValue = this.value
    this.loadItemGroupList()
    this.loadItemList()
  },

  methods: {

    /**
     * 대분류 로드
     * @returns {Promise<void>}
     */
    async loadItemGroupList() {
      try {
        const result = await this.$request(PATH.ITEM.GROUP)
          .setObject({})
          .enqueue();
        const items = result.data

        this.itemGroupCodeList = items.map(e => {
          return {value: e.codeKey, label: e.codeValue, key: `item-standard-${e.codeKey}`}
        })
      } catch (e) {
        console.error(e)
      }
    },

    /**
     * 품목 로드(프린터 제외)
     * @returns {Promise<void>}
     */
    async loadItemList() {
      try {
        const result = await this.$request(PATH.ITEM.LIST)
          .setObject({})
          .enqueue();
        const items = result.data

        this.itemCodeList = items.map(e => {
          return {
            value: e.itemId,
            label: e.itemName,
            key: `item-itemId-${e.itemId}`,
            groupValue: e.itemGroup1
          }
        })
      } catch (e) {
        console.error(e)
      }
    },

    /**
     * 하위 계약 추가
     */
    addSubContract() {
      if(!this.accountEditPermission){
        return this.$snackbarError(ETC.PERMISSION.ACCOUNT_REGISTER_EDIT);
      }
      const addItem = cloneDeep(this.dummy)
      addItem.startSalesMonth = this.$now().startOf("month").toSQLDate();
      addItem.accountId = this.rentalContractInfo.accountId

      this.internalValue.splice(0, 0, addItem)
    },

  },
}
</script>

<style scoped>
.add-button-wrap {
  background-color: #f3f3f3;
  padding: 12px;
}

.add-button {
  width: 100%;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: bold;
  color: #fff;
  background-color: #0C98FE;
  padding: 8px 4px;
  border-radius: 3px;
}

.contract-sub-list-wrap {
  padding-bottom: 14px;
  background-color: #f3f3f3;
}

.contract-sub-item {
  background: #fff;
}

.contract_wrap {
  background-color: #f3f3f3;
  padding: 6px 14px;
}
</style>
